import { IconStore } from '../../IconStore';

function DashboardExpandableNavItem(props) {
  const menuName = props.text.replace(/\s/g, '').toLowerCase();
  const childItems = props.items.map((item, idx) =>
    <DashboardExpandableNavChildItem key={idx} text={item.text}
      view={item.view} changeView={props.changeView} selection={props.selection} />
  );

  return (
    <li className="nav-item">
      <span className="nav-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target={"#submenu-" + menuName}>
        <span>
        <span className="sidebar-icon"><span className={"fas " + IconStore[props.iconName]}></span></span> 
          {'\u00A0'}{props.text}
        </span>
        <span className="link-arrow"><span className={"fas " + IconStore['rightArrow']}></span></span> 
      </span>
      <div className="multi-level collapse " role="list" id={"submenu-" + menuName}>
        <ul className="flex-column nav">
          {childItems}
        </ul>
      </div>
    </li>
  );
}

function DashboardExpandableNavChildItem(props) {
  const selected = props.selection === props.view ? "active" : "";
  return (
    <li className={"nav-item " + selected}>
      <a className="nav-link d-none d-md-block" href="#" onClick={() => props.changeView(props.view)}>
        <span>{props.text}</span>
      </a>
      <a href="#sidebarMenu" onClick={() => props.changeView(props.view)} className="nav-link d-block d-sm-block d-md-none" data-toggle="collapse" data-target="#sidebarMenu">
        <span>{props.text}</span>
      </a>
    </li>
  );
}

export default DashboardExpandableNavItem;

import 'simplebar';
import JashsoftLogo from '../JashsoftLogo';
import DashboardNavMobile from './DashboardNavMobile';
import DashboardNavMobileUserMenu from './DashboardNavMobileUserMenu';
import DashboardNavItemList from './DashboardNavItemList';

function DashboardNav(props) {
  const logoStyle = {
    width: '120px',
    height: '70%',
    marginTop: '4px',
    marginBottom: '25px'
  };

  return (
    <span>
      <DashboardNavMobile />
      <nav id="sidebarMenu" className="sidebar d-md-block bg-primary text-white collapse" data-simplebar>
        <div className="sidebar-inner px-4 pt-3">
          <JashsoftLogo fill="white" style={logoStyle} className="navbar-expand-lg" />
          <DashboardNavMobileUserMenu showAuthView={props.showAuthView} name={props.name} />
          <DashboardNavItemList changeView={props.changeView} />
        </div>
      </nav>
    </span>
  );
}

export default DashboardNav;

import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Config } from '../../Config';

class RegisterUser {
  resolve = null;
  reject = null;

  action(modelInstance) {
    const userPool = new CognitoUserPool({
      UserPoolId: Config.cognitoUserPoolId,
      ClientId: Config.cognitoClientId
    });

    const fullName = new CognitoUserAttribute({
      Name: 'name',
      Value: modelInstance.fullName
    });
    const emailAddress = new CognitoUserAttribute({
      Name: 'email',
      Value: modelInstance.emailAddress
    });

    userPool.signUp(modelInstance.emailAddress, modelInstance.password, 
      [fullName, emailAddress], null, this._processResponse.bind(this));

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  _processResponse(error, data) {
    if (error) {
      this.reject();
    } else {
      this.resolve();
    }
  }
}

export default RegisterUser;

import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { Config } from '../../Config';

class SignOutUser {
  action() {
    const userPool = new CognitoUserPool({
      UserPoolId: Config.cognitoUserPoolId,
      ClientId: Config.cognitoClientId
    });

    const user = userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
      if (user) {
        user.signOut();
        resolve();
      } else {
        reject();
      }
    });
  }
}

export default SignOutUser;

import Transaction from './Transaction';

class Portfolio {
  get transactions() {
    return Transaction.findAll() || [];
  }

  getPortfolioValue() {
    const stocks = this._getStocksMap();
    return this._getPortfolioValueForStocksMap(stocks);
  }

  getChartData() {
    return {
      labels: ['12/1','12/2','12/3','12/4','12/5','12/6','12/7','12/8','12/9','12/10','12/11','12/12','12/13','12/14','12/15'],
      series: [
        [12, 9, 7, 10, 9, 20, 90, 77, 43, 45, 70, 87, 88, 102, 101]
      ]
    };
  }

  getTopSectors() {
    return {
      labels: ['Technology', 'Finance', 'Industrials', 'Consumer Discretionary'],
      series: [20, 20, 20, 40]
    };
  }

  getCountryExposure() {
    return ['us', 'ch', 'au'];
  }

  getTopHoldings() {
    const { transactions } = this;
    if (transactions.length < 1) return [];

    const stocks = this._getStocksMap();
    const portfolioValue = this._getPortfolioValueForStocksMap(stocks);
    const topStocks = Object.keys(stocks).sort((a, b) => {
      return stocks[a].currentValue - stocks[b].currentValue;
    });

    const topHoldings = [];
    let idx = 5;
    while (topStocks.length > 0 && idx > 0) {
      const ticker = topStocks.pop();
      const holdingValue = stocks[ticker].currentValue;
      topHoldings.push({
        text: ticker,
        percent: ((holdingValue / portfolioValue) * 100).toFixed(2)
      });
      idx--;
    }

    return topHoldings;
  }

  getTopGainers() {
    return {
      columns: ['Stock', 'Gain'],
      items: [
        { text: 'AAPL', percent: 3315 },
        { text: 'GOOG', percent: 1220 },
        { text: 'MSFT', percent: 1045 }
      ]
    };
  }

  getTopLosers() {
    return {
      columns: ['Stock', 'Loss'],
      items: [
        { text: 'BA', percent: -35 },
        { text: 'RTX', percent: -20 },
        { text: 'LMT', percent: -15 }
      ]
    };
  }

  getAssetAllocation () {
    return {
      labels: ['Equities', 'Fixed Income', 'Cash'],
      series: [40, 30, 20]
    };
  }

  getBeta() {
    return 1.2;
  }

  getCAGR() {
    return 7;
  }

  getSharpeRatio() {
    return 1.56;
  }

  getHoldings() {
    const items = [];

    const stocks = this._getStocksMap();
    const tickers = Object.keys(stocks);
    tickers.forEach((ticker) => {
      const currentStock = stocks[ticker];
      items.push({
        ticker: ticker,
        purchasePrice: currentStock.avgPurchasePrice.toFixed(2),
        quantity: currentStock.totalShares,
        currentValue: currentStock.currentValue,
        change: Math.round(((currentStock.lastPrice - currentStock.avgPurchasePrice) / currentStock.avgPurchasePrice) * 100)
      });
    });

    return {
      columns: [
        'Stock Ticker',
        'Avg Purchase Price',
        'Total Shares',
        'Current Value',
        'Gain / Loss (%)'
      ],
      items
    };
  }

  _getStocksMap() {
    const { transactions } = this;
    if (transactions.length < 1) return [];

    const stocks = {};
    transactions.forEach((txn) => {
      const alreadyAdded = stocks[txn.stockTicker];
      if (alreadyAdded) {
        stocks[txn.stockTicker] = {
          avgPurchasePrice: ((alreadyAdded.avgPurchasePrice * alreadyAdded.totalShares) + (txn.purchasePrice * txn.numberOfShares)) /
            (alreadyAdded.totalShares + txn.numberOfShares),
          totalShares: alreadyAdded.totalShares + txn.numberOfShares,
          currentValue: txn.getStock().lastPrice * (alreadyAdded.totalShares + txn.numberOfShares),
          lastPrice: txn.getStock().lastPrice
        };
      } else {
        stocks[txn.stockTicker] = {
          avgPurchasePrice: txn.purchasePrice,
          totalShares: txn.numberOfShares,
          currentValue: txn.getStock().lastPrice * txn.numberOfShares,
          lastPrice: txn.getStock().lastPrice
        };
      }
    });

    return stocks;
  }

  _getPortfolioValueForStocksMap(stocks) {
    if (Object.keys(stocks).length < 1) return 0;
    if (Object.keys(stocks).length === 1) {
      const key = Object.keys(stocks)[0];
      return stocks[key].currentValue;
    }
    return Object.values(stocks).reduce((prev, curr) => {
      // prev is `stock` object for first iteration, then sum value
      if (typeof prev === 'object') {
        return prev.currentValue + curr.currentValue;
      } else {
        return prev + curr.currentValue;
      }
    });
  }
}

export default Portfolio;

import React from 'react';
import InputField from '../ui-elements/InputField';
import ActionButton from '../ui-elements/ActionButton';
import SecondaryButton from '../ui-elements/SecondaryButton';

class SignInFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: '',
      password: ''
    };

    this.errorAlert = React.createRef();

    this.updateValues = this.updateValues.bind(this);
    this.loginSubmit = this.loginSubmit.bind(this);
    this.showError = this.showError.bind(this);
  }

  updateValues(event) {
    const stateKey = event.target.getAttribute('statekey');
    this.setState({ [stateKey]: event.target.value }, this.validateForm);
  }

  loginSubmit() {
    this.props.loginUser(this.state.emailAddress, this.state.password);
  }

  showError() {
    const alert = this.errorAlert.current;
    alert.style.display = 'block';
  }

  onSubmit(event) {
    event.preventDefault();
  }

  render() {
    const errorStyle = {
      display: 'none'
    };

    return (
      <form onSubmit={this.onSubmit}>
        <div className="alert alert-danger" style={errorStyle} role="alert" ref={this.errorAlert}>
          Invalid E-Mail address or Password. Please login again or click "Forgot Password?" to reset your password.
        </div>
        <InputField iconName="email" label="Email Address" type="email"
          placeholder="name@provider.com" autocomplete statekey="emailAddress" onChange={this.updateValues} focus={true} />
        <InputField iconName="password" label="Password" type="password"
          placeholder="Password" autocomplete statekey="password" onChange={this.updateValues} />
        <div className="text-center justify-content-between mb-4">
          <a href="#" onClick={this.props.showForgotPassword} className="large ">Forgot Password?</a>
        </div>
        <ActionButton onClick={this.loginSubmit} text="Sign In" fullWidth={true} />
        <SecondaryButton onClick={this.props.showSignUp} text="New user? Sign Up"/>
      </form>
    );
  }
}

export default SignInFields;

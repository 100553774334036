import { IconStore } from '../../IconStore';

function DashboardNavItem(props) {
  const selected = props.selected ? "active" : "";
  return (
    <li className={"nav-item " + selected}>
      <a href="#" onClick={props.onClick} className="nav-link d-none d-md-block">
        <span className="sidebar-icon"><span className={"fas " + IconStore[props.iconName]}></span></span>
        <span>{'\u00A0'}{props.text}</span>
      </a>
      <a href="#sidebarMenu" onClick={props.onClick} className="nav-link d-block d-sm-block d-md-none" data-toggle="collapse" data-target="#sidebarMenu">
        <span className="sidebar-icon"><span className={"fas " + IconStore[props.iconName]}></span></span>
        <span>{'\u00A0'}{props.text}</span>
      </a>
    </li>
  );
}

export default DashboardNavItem;

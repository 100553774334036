import React from 'react';
import HoldingsTable from './HoldingsTable';
import Transaction from '../../data/models/Transaction';
import { IconStore } from '../../IconStore';
import InputField from '../ui-elements/InputField';
import DateField from '../ui-elements/DateField';
import Modal from '../ui-elements/Modal';

class TransactionsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transactionsData: {
        columns: [
          'Date',
          'Stock Ticker',
          'Purchase Price',
          'No. of Shares'
        ],
        items: []
      }
    };

    this.populateTable = this.populateTable.bind(this);
    this.addTransaction = this.addTransaction.bind(this);
    this.updateTransaction = this.updateTransaction.bind(this);
    this.deleteTransaction = this.deleteTransaction.bind(this);
  }

  componentDidMount() {
    Transaction.fetch()
      .then((transactions) => this.populateTable(transactions));
  }

  populateTable(transactions) {
    // Sort by date
    transactions.sort((a, b) => {
      const keyA = new Date(a.date);
      const keyB = new Date(b.date);
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });

    const items = [];
    transactions.forEach((txn) => {
      items.push(txn.toJSON(true));
    });

    const updatedData = this.state.transactionsData;
    updatedData.items = items;
    this.setState({
      transactionsData: updatedData
    });
  }

  addTransaction(event) {
    const modalData = event.modalData;
    new Transaction({
      date: modalData['PurchaseDate'],
      stockTicker: modalData['StockTicker'],
      purchasePrice: modalData['StockPurchasePrice'],
      numberOfShares: modalData['NoOfshares']
    }).save().then(() => this.populateTable(Transaction.findAll()));
  }

  updateTransaction(event) {
    const id = event.target.dataset.id;
    const txn = Transaction.find(id);

    const modalData = event.modalData;
    txn.purchasePrice = modalData['StockPurchasePrice'];
    txn.numberOfShares = modalData['NoOfshares'];

    txn.save().then(() => this.populateTable(Transaction.findAll()));
  }

  deleteTransaction(event) {
    const id = event.target.dataset.id;
    Transaction.find(id).delete().then(() => this.populateTable(Transaction.findAll()));
  }

  render() {
    let content = <NoTransactionAlert />;
    if (this.state.transactionsData.items.length > 0) {
      content = <HoldingsTable data={this.state.transactionsData} />;
    }

    return (
      <span>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <button className="btn btn-primary btn-sm mr-2" data-toggle="modal" data-target="#modal-add-transaction">
            <span className={"mr-2 fas " + IconStore['plus']}></span>
            Add transaction
          </button>
          <TransactionModals addTransaction={this.addTransaction}
            updateTransaction={this.updateTransaction}
            deleteTransaction={this.deleteTransaction}/>
        </div>
        {content}
      </span>
    );
  }
}

function NoTransactionAlert(props) {
  return (
    <div className="alert alert-primary" role="alert">
      No Transactions. Click on "Add transaction" to add a transaction.
    </div>
  );
}

class TransactionModals extends React.Component {
  render() {
    return (
      <span>
        <Modal modalId="modal-add-transaction" title="Add Transaction" bodyElement={(<span>
          <InputField text="Stock Ticker" label="Stock Ticker" placeholder="Stock Ticker" />
          <DateField text="Purchase Date" />
          <InputField text="Stock Purchase Price" label="Stock Purchase Price" placeholder="Stock Purchase Price" />
          <InputField text="No Of shares" label="No. of shares" placeholder="No. of shares" />
          </span>)} actionButtonTitle="Add" onClick={this.props.addTransaction} />
        <Modal modalId="modal-edit-transaction" title="Edit Transaction" bodyElement={(<span>
              <InputField modelClass={Transaction.name} modelKey="purchasePrice" text="Stock Purchase Price" label="Stock Purchase Price" placeholder="Stock Purchase Price" />
              <InputField modelClass={Transaction.name} modelKey="numberOfShares" text="No Of shares" label="No. of shares" placeholder="No. of shares" />
              </span>)} actionButtonTitle="Update" onClick={this.props.updateTransaction} />
        <Modal modalId="modal-delete-transaction" title="Delete Transaction?"
          bodyElement={(<p>Are you sure you want to delete this transaction?</p>)}
          actionButtonTitle="Delete" destructive={true} onClick={this.props.deleteTransaction} />
      </span>
    );
  }
}

export default TransactionsView;

import WorldMap from 'react-world-map';
import { CountryMap } from '../../../CountryMap';

const getContinents = (countries) => {
  const selection = new Set([]);

  countries.forEach((country) => {
    selection.add(CountryMap[country]);
  });

  return Array.from(selection);
};

function GeographicExposure(props) {
  const selection = getContinents(props.countries);
  const cardStyle = {
    marginTop: '-5px',
    marginBottom: '-5px'
  };

  return (
    <div className="col-12 col-xl-5 mb-4">
      <div className="card border-light shadow-sm">
        <div className="card-header d-flex flex-row align-items-center">
          <h2 className="h5 mb-0">Geographic Exposure</h2>
        </div>
        <div className="card-body" style={cardStyle}>
          <WorldMap multiple={true} selected={selection} onSelect={() => {}} />
        </div>
      </div>
    </div>
  );
}

export default GeographicExposure;

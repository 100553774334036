import React from 'react';
import { Datepicker } from 'vanillajs-datepicker';

class DateField extends React.Component {
  componentDidMount() {
    const elementId = this.props.text.split(' ').join('');
    const datePickerElement = document.getElementById(elementId);
    new Datepicker(datePickerElement, {
      autohide: true
    });
  }

  render() {
    const elementId = this.props.text.split(' ').join('');
    return (
      <div className="form-group mb-4">
        <label>{this.props.text}</label>
        <div className="input-group">
          <input id={elementId} data-datepicker="" className="form-control datepicker-input" type="text" placeholder="mm/dd/yyyy" />
        </div>
      </div>
    );
  }
}

export default DateField;

import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';

class BetaGaugeCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guageWidth: 0
    };
    this.card = React.createRef();

    this.setGaugeWidth = () => {
      this.setState({
        guageWidth: (this.card.current.clientWidth - 65)
      });
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.setGaugeWidth);
    this.setGaugeWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setGaugeWidth);
  }

  render() {
    const segmentColors=['#ebbb38', '#0ca616', '#cf3213'];
    const cardStyle = {
      paddingBottom: '75px'
    };

    return (
      <div className="col-12 col-xl-3 mb-4" ref={this.card}>
        <div className="card border-light shadow-sm">
          <div className="card-header d-flex flex-row align-items-center">
            <h2 className="h5 mb-0">Market Risk (Beta)</h2>
          </div>
          <div className="card-body" style={cardStyle}>
            <h2 className="h1 text-center">{this.props.value}</h2>
            <span className="align-items-center gauge">
              <ReactSpeedometer forceRender={true} value={this.props.value} minValue={0} maxValue={2} segments={3} maxSegmentLabels={0}
                segmentColors={segmentColors} needleColor="#a6a6a6" currentValueText="" height={190} width={this.state.guageWidth} ringWidth={45} />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default BetaGaugeCard;

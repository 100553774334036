import { QueryCommand, PutItemCommand, DeleteItemCommand } from '@aws-sdk/client-dynamodb';
import { Config } from '../../Config';
import BaseModelHandler from './BaseModelHandler';
import Transaction from '../models/Transaction';
import Stock from '../models/Stock';
import DataManager from '../DataManager';

const sendDatabaseCommandForParam = (command, param) => {
  const dataManager = DataManager.getInstance();
  const dynamoDbClient = dataManager.getDatabaseClient();
  return dynamoDbClient.send(new command(param));
};

class TransactionHandler extends BaseModelHandler {
  modelType = Transaction;

  read() {
    const params = {
      KeyConditionExpression: "user_sub = :sub",
      ExpressionAttributeValues: {
        ":sub": { S: DataManager.getInstance().congitoUserIdentityId }
      },
      TableName: Config.dynamoDbTables.TRANSACTION_TABLE
    };

    const fetchCalls = [];
    const databaseRead = sendDatabaseCommandForParam(QueryCommand, params).then((data) => {
      const items = data.Items;
      const modelInstances = [];
      if (items) {
        items.forEach((txn) => {
          let transaction = Transaction.find(Object.values(txn.id).pop());
          if (transaction) {
            transaction.date = new Date(Object.values(txn.date).pop()).toLocaleDateString();
            transaction.stockTicker = Object.values(txn.stockTicker).pop();
            transaction.purchasePrice = Object.values(txn.purchasePrice).pop();
            transaction.numberOfShares = Object.values(txn.numberOfShares).pop();
          } else {
            transaction = new Transaction({
              id: Object.values(txn.id).pop(),
              date: new Date(Object.values(txn.date).pop()).toLocaleDateString(),
              stockTicker: Object.values(txn.stockTicker).pop(),
              purchasePrice: Object.values(txn.purchasePrice).pop(),
              numberOfShares: Object.values(txn.numberOfShares).pop()
            });

            const stock = Stock.find(transaction.stockTicker);
            if (!stock) {
              fetchCalls.push(Stock.fetch(transaction.stockTicker));
            }
          }

          modelInstances.push(transaction);
        });
      }

      return modelInstances;
    });

    fetchCalls.push(databaseRead);
    return Promise.all(fetchCalls).then((results) => results[0]);
  }

  update(modelInstance) {
    const params = {
      TableName: Config.dynamoDbTables.TRANSACTION_TABLE,
      Item: modelInstance.getAsDatabaseCommand()
    };

    return sendDatabaseCommandForParam(PutItemCommand, params);
  }

  delete(modelInstance) {
    const params = {
      TableName: Config.dynamoDbTables.TRANSACTION_TABLE,
      Key: modelInstance.getIdAsDatabaseCommand()
    };

    return sendDatabaseCommandForParam(DeleteItemCommand, params);
  }
}

export default TransactionHandler;

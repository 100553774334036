import React from 'react';
import InputField from '../ui-elements/InputField';
import Checkbox from '../ui-elements/Checkbox';
import ActionButton from '../ui-elements/ActionButton';
import SecondaryButton from '../ui-elements/SecondaryButton';
import Utils from '../../data/Utils';

class SignUpFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: '',
      emailAddress: '',
      password: '',
      confirmPassword: '',
      isFormValid: false
    };

    this.errorAlert = React.createRef();

    this.updateValues = this.updateValues.bind(this);
    this.signupSubmit = this.signupSubmit.bind(this);
    this.showError = this.showError.bind(this);
    this.nameValid = this.nameValid.bind(this);
    this.confirmPasswordValid = this.confirmPasswordValid.bind(this);
  }

  updateValues(event) {
    const stateKey = event.target.getAttribute('statekey');
    this.setState({ [stateKey]: event.target.value }, this.validateForm);
  }

  nameValid(name) {
    return name.length > 0;
  }

  confirmPasswordValid(confirmPassword) {
    return confirmPassword === this.state.password;
  }

  validateForm() {
    const valid = this.nameValid(this.state.fullName) > 0 &&
                  Utils.isValidEmail(this.state.emailAddress) &&
                  Utils.isValidPassword(this.state.password) &&
                  this.confirmPasswordValid(this.state.confirmPassword);
    this.setState({
      isFormValid: valid
    });
  }

  signupSubmit() {
    this.props.registerUser(this.state.fullName, this.state.emailAddress, this.state.password);
  }

  showError() {
    const alert = this.errorAlert.current;
    alert.style.display = 'block';
  }

  onSubmit(event) {
    event.preventDefault();
  }

  render() {
    const errorStyle = {
      display: 'none'
    };

    return (
      <form onSubmit={this.onSubmit}>
        <div className="alert alert-danger" style={errorStyle} role="alert" ref={this.errorAlert}>
          Unexpected error occurred. Please try again.
        </div>
        <InputField iconName="person" label="Full Name" type="text" value={this.state.fullName}
          placeholder="John Doe" statekey="fullName" onChange={this.updateValues} focus={true}
          validateFunction={this.nameValid} validationErrorText="Please enter a name." />
        <InputField iconName="email" label="Email Address" type="email" value={this.state.emailAddress}
          placeholder="name@provider.com" statekey="emailAddress" onChange={this.updateValues}
          validateFunction={Utils.isValidEmail} validationErrorText="Please enter a valid E-mail address." />
        <InputField iconName="password" label="Password" type="password" value={this.state.password}
          placeholder="Password" statekey="password" onChange={this.updateValues}
          hintText="Must be at least 8 characters, and include Upper case, lower case, numbers and special characters."
          validateFunction={Utils.isValidPassword} validationErrorText="Please enter a valid Password." />
        <InputField iconName="password" label="Confirm Password" type="password" value={this.state.confirmPassword}
          placeholder="Confirm Password" statekey="confirmPassword" onChange={this.updateValues}
          validateFunction={this.confirmPasswordValid} validationErrorText="Password and confirm password should be the same." />
        <Checkbox text="I agree to the terms and conditions" />
        <ActionButton onClick={this.signupSubmit} disabled={!this.state.isFormValid} text="Sign Up" fullWidth={true} />
        <SecondaryButton onClick={this.props.showSignIn} text="Already an user? Sign In"/>
      </form>
    );
  }
}

export default SignUpFields;

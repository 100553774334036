import React from 'react';
import InputField from '../ui-elements/InputField';
import ActionButton from '../ui-elements/ActionButton';
import SecondaryButton from '../ui-elements/SecondaryButton';
import Utils from '../../data/Utils';

class ForgotPasswordFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: '',
      code: '',
      password: '',
      confirmPassword: ''
    };

    this.successAlert = React.createRef();
    this.requestCodeButton = React.createRef();
    this.passwordFields = React.createRef();

    this.updateEmailValue = this.updateEmailValue.bind(this);
    this.updateCodeValue = this.updateCodeValue.bind(this);
    this.updatePasswordValue = this.updatePasswordValue.bind(this);
    this.updateConfirmPasswordValue = this.updateConfirmPasswordValue.bind(this);
    this.forgotPasswordSubmit = this.forgotPasswordSubmit.bind(this);
    this.changePasswordSubmit = this.changePasswordSubmit.bind(this);
    this.confirmPasswordValid = this.confirmPasswordValid.bind(this);
  }

  componentDidMount() {
    this.passwordFields.current.style.display = 'none';
  }

  updateEmailValue(event) {
    this.setState({
      emailAddress: event.target.value
    });
  }

  updateCodeValue(event) {
    this.setState({
      code: event.target.value
    });
  }

  updatePasswordValue(event) {
    this.setState({
      password: event.target.value
    });
  }

  updateConfirmPasswordValue(event) {
    this.setState({
      confirmPassword: event.target.value
    });
  }

  confirmPasswordValid(confirmPassword) {
    return confirmPassword === this.state.password;
  }

  forgotPasswordSubmit() {
    this.props.forgotPassword(this.state.emailAddress);
  }

  changePasswordSubmit() {
    this.props.changePassword(this.state.emailAddress, this.state.code, this.state.password);
  }

  showSuccessAlert() {
    const alert = this.successAlert.current;
    alert.innerText = 'Password reset email sent.';
    alert.className = 'alert alert-success';
    alert.style.display = 'block';

    this.requestCodeButton.current.style.display = 'none';
    this.passwordFields.current.style.display = 'block';
  }

  showFailureAlert() {
    const alert = this.successAlert.current;
    alert.innerText = 'Password reset failed. Please make sure the code is correct.';
    alert.className = 'alert alert-danger';
    alert.style.display = 'block';
  }

  onSubmit(event) {
    event.preventDefault();
  }

  render() {
    const errorStyle = {
      display: 'none'
    };

    return (
      <form onSubmit={this.onSubmit}>
        <div style={errorStyle} role="alert" ref={this.successAlert}></div>
        <InputField iconName="email" label="Email Address" type="email"
          placeholder="name@provider.com" onChange={this.updateEmailValue} autocomplete focus={true} />
        <span ref={this.passwordFields}>
          <ResetCodeInput updateCodeValue={this.updateCodeValue} resendCode={this.forgotPasswordSubmit} />
          <InputField iconName="password" label="Password" type="password"
            placeholder="Password" onChange={this.updatePasswordValue}
            validateFunction={Utils.isValidPassword} validationErrorText="Please enter a valid Password."
            hintText="Must be at least 8 characters, and include Upper case, lower case, numbers and special characters." />
          <InputField iconName="password" label="Confirm Password" type="password"
            placeholder="Confirm Password" onChange={this.updateConfirmPasswordValue}
            validateFunction={this.confirmPasswordValid} validationErrorText="Password and confirm password should be the same." />
          <ActionButton onClick={this.changePasswordSubmit} text="Change Password" fullWidth={true} />
          <SecondaryButton onClick={this.props.cancelForgotPassword} text="Cancel"/>
        </span>
        <span ref={this.requestCodeButton}>
          <ActionButton onClick={this.forgotPasswordSubmit} text="Reset Password" fullWidth={true} />
          <SecondaryButton onClick={this.props.cancelForgotPassword} text="Cancel"/>
        </span>
      </form>
    );
  }
}

function ResetCodeInput(props) {
  const codeBoxWidth = {
    display: 'inline-block',
    width: '50%'
  };
  const buttonWidth = {
    display: 'inline-block',
    marginLeft: '5%',
    width: '45%'
  };

  return (
    <span>
      <span style={codeBoxWidth}>
        <InputField iconName="asterisk" label="Code in Email" placeholder="000000" onChange={props.updateCodeValue}
          hintText="Enter the code sent to your email address, or request a new code." />
      </span>
      <button className="btn btn-block btn-secondary" style={buttonWidth}
        onClick={props.resendCode}>Resend Code</button>
    </span>
  );
}

export default ForgotPasswordFields;

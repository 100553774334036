import React from 'react';
import DataManager from '../../data/DataManager';

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();
    this.actionButton = React.createRef();

    this.primaryAction = this.primaryAction.bind(this);

    this.listener = (event) => {
      const parentDataId = event.relatedTarget.dataset.id;
      this.actionButton.current.dataset.id = parentDataId;

      const modal = this.modal.current;
      const inputs = Array.from(modal.querySelectorAll('input'));
      const dataManager = DataManager.getInstance();

      inputs.forEach((input) => {
        const {modelclass, modelkey} = input.dataset;
        if (modelclass && modelkey) {
          const modelClass = dataManager.getModelClassFor(modelclass);
          const modelInstance = modelClass.find(parentDataId);
          if (modelInstance) {
            input.value = modelInstance[modelkey];
          }
        } else {
          input.value = '';
        }
      });
    };
  }

  componentDidMount() {
    this.modal.current.addEventListener('show.bs.modal', this.listener);
  }

  componentWillUnmount() {
    this.modal.current.removeEventListener('show.bs.modal', this.listener);
  }

  primaryAction(event) {
    const modal = this.modal.current;
    const inputs = Array.from(modal.querySelectorAll('input'));
    const modalData = {};
    inputs.forEach((input) => modalData[input.id] = input.value);

    event.modalData = modalData;
    this.props.onClick(event);
  }

  render() {
    let actionButtonClass = "btn btn-secondary";
    const isDestructive = this.props.destructive;
    if (isDestructive) {
      actionButtonClass = "btn btn-danger";
    }

    return (
      <div ref={this.modal} className="modal fade" id={this.props.modalId} tabIndex="-1" role="dialog" aria-labelledby="modal-default" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="h4 modal-title">{this.props.title}</h2>
            </div>
            <div className="modal-body">
              {this.props.bodyElement}
            </div>
            <div className="modal-footer">
              <button ref={this.actionButton} type="button" className={actionButtonClass} onClick={this.primaryAction} data-dismiss="modal">{this.props.actionButtonTitle}</button>
              <button type="button" className="btn btn-link text-gray" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;

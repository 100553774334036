function SharpeRatioCard(props) {
  return (
    <div className="card border-light shadow-sm">
      <div className="card-body">
        <div className="row d-block d-xl-flex align-items-center">
          <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
            <div className="icon icon-shape icon-md icon-shape-secondary rounded mr-4">
              <span><i>S</i><sub>a</sub></span>
            </div>
            <div className="d-sm-none">
              <h2 className="h5">Sharpe Ratio</h2>
              <h3 className="mb-1">{props.value}</h3>
            </div>
          </div>
          <div className="col-12 col-xl-7 px-xl-0">
            <div className="d-none d-sm-block">
              <h2 className="h5">Sharpe Ratio</h2>
              <h3 className="mb-1">{props.value}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SharpeRatioCard;

import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import BaseModelHandler from './BaseModelHandler';
import { Config } from '../../Config';
import User from '../models/User';

class UserHandler extends BaseModelHandler {
  modelType = User;

  updateResolve = null;
  updateReject = null;

  update(modelInstance) {
    const userPool = new CognitoUserPool({
      UserPoolId: Config.cognitoUserPoolId,
      ClientId: Config.cognitoClientId
    });

    const user = userPool.getCurrentUser();
    const fullName = new CognitoUserAttribute({
      Name: 'name',
      Value: modelInstance.fullName
    });
    const emailAddress = new CognitoUserAttribute({
      Name: 'email',
      Value: modelInstance.emailAddress
    });

    const updatePromise = new Promise((resolve, reject) => {
      this.updateResolve = resolve;
      this.updateReject = reject;
    });

    user.getSession((error, data) => {
      if (!error) {
        user.updateAttributes([fullName, emailAddress], (error, result) => {
          if (error) {
            this.updateReject(error);
          } else {
            this.updateResolve(result);
          }
        });
      }
    });

    return updatePromise;
  }
}

export default UserHandler;

import React from 'react';
import User from '../../data/models/User';
import Utils from '../../data/Utils';
import ActionButton from '../ui-elements/ActionButton';
import InputField from '../ui-elements/InputField';

class MyAccountView extends React.Component {
  constructor(props) {
    super(props);

    const users = User.findAll();
    if (users.length > 0) {
      const user = users[0];
      this.state = {
        user: user,
        originalName: user.fullName,
        originalEmail: user.emailAddress
      };
    }

    this.updateValues = this.updateValues.bind(this);
    this.submitUpdateInformation = this.submitUpdateInformation.bind(this);
  }

  componentWillUnmount() {
    const { user } = this.state;
    user.fullName = this.state.originalName;
    user.emailAddress = this.state.originalEmail;
  }

  updateValues(event) {
    const { user } = this.state;
    const stateKey = event.target.getAttribute('statekey');
    user[stateKey] = event.target.value;

    this.setState({
      user: user
    });
  }

  submitUpdateInformation() {
    const { user } = this.state;
    this.setState({
      originalName: user.fullName,
      originalEmail: user.emailAddress
    });

    this.state.user.save();
  }

  onSubmit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <span>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2"></div>
        <div className="row">
          <div className="col-12 col-xl-8">
            <div className="card card-body bg-white border-light shadow-sm">
              <h2 className="h5 mb-4">Account information</h2>
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <InputField label="Full Name" value={this.state.user.fullName} statekey="fullName" onChange={this.updateValues} />
                  </div>
                  <div className="col-md-6 mb-3">
                    <InputField label="E-Mail Address" value={this.state.user.emailAddress} statekey="emailAddress" onChange={this.updateValues}
                      validateFunction={Utils.isValidEmail} validationErrorText="Please enter a valid E-mail address." />
                  </div>
                </div>
                <ActionButton onClick={this.submitUpdateInformation} text="Update information" />
              </form>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

export default MyAccountView;

import OperationType from '../OperationType';

class BaseModelHandler {
  modelType = null;

  handlers = {};

  constructor() {
    this.registerHandler(OperationType.CREATE, this.create);
    this.registerHandler(OperationType.READ, this.read);
    this.registerHandler(OperationType.UPDATE, this.update);
    this.registerHandler(OperationType.DELETE, this.delete);
  }

  registerHandler(operation, handler) {
    this.handlers[operation] = handler;
  }

  create(modelInstance) {
    return Promise.resolve();
  }

  read(modelInstance) {
    return Promise.resolve();
  }

  update(modelInstance) {
    return Promise.resolve();
  }

  delete(modelInstance) {
    return Promise.resolve();
  }
}

export default BaseModelHandler;

import ChartistGraph from 'react-chartist';
import legend from 'chartist-plugin-legend';

function TopSectorsCard(props) {
  const options = {
    height: '150px',
    width: '150px',
    showLabel: false,
    plugins: [
      legend()
    ]
  };

  const cardMargin = {
    paddingLeft: '5px',
    paddingRight: '5px',
    marginLeft: '15px'
  };

  return (
    <div className="col-12 col-xl-4 mb-4">
      <div className="card border-light shadow-sm">
        <div className="card-header d-flex flex-row align-items-center">
          <div className="h4">Top Sectors</div>
        </div>
        <div className="card-body" style={cardMargin}>
          <ChartistGraph data={props.chartData} type="Pie" options={options} />
        </div>
      </div>
    </div>
  );
}

export default TopSectorsCard;

import React from 'react';
import { IconStore } from '../../IconStore';

const bootstrap = global.bootstrap;

class InputField extends React.Component {
  constructor(props) {
    super(props);

    this.tooltip = null;

    this.inputControl = React.createRef();
    this.inputErrorText = React.createRef();

    this.showHint = this.showHint.bind(this);
    this.hideHint = this.hideHint.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.validateInput = this.validateInput.bind(this);
  }

  showHint() {
    if (!this.props.hintText) return;

    if (this.tooltip) {
      this.tooltip.show();
    } else {
      const el = this.inputControl.current;
      this.tooltip = new bootstrap.Tooltip(el);
      this.tooltip.show();
    }
  }

  hideHint() {
    if (this.tooltip) {
      this.tooltip.hide();
    }
  }

  validateInput() {
    if (this.props.validateFunction) {
      const value = this.inputControl.current.value;
      const isValid = this.props.validateFunction(value);
      if (isValid || value === '') {
        this.inputErrorText.current.style.display = 'none';
        this.inputControl.current.classList.remove('is-invalid');
      } else {
        this.inputErrorText.current.style.display = 'block';
        this.inputControl.current.classList.add('is-invalid');
      }
    }
  }

  onBlur() {
    this.hideHint();
    this.validateInput();
  }

  render() {
    let icon;
    if (this.props.iconName) {
      icon = (<span className="input-group-text" id="basic-addon3">
                <span className={"fas " + IconStore[this.props.iconName]}></span>
              </span>);
    }

    const text = this.props.text || ('Text' + Math.round(Math.random() * 1000));
    const elementId = text.split(' ').join('');

    return (
      <div className="form-group mb-4">
        <label>{this.props.label}</label>
        <div className="input-group">
          {icon}
          <input id={elementId} type={this.props.type} autoFocus={this.props.focus} onChange={this.props.onChange}
            onFocus={this.showHint} onBlur={this.onBlur} statekey={this.props.statekey}
            className="form-control" value={this.props.value} placeholder={this.props.placeholder} ref={this.inputControl}
            data-bs-toggle="tooltip" data-bs-trigger="manual" title={this.props.hintText}
            data-modelclass={this.props.modelClass} data-modelkey={this.props.modelKey} />
        </div>
        <div className="invalid-feedback" ref={this.inputErrorText}>
          {this.props.validationErrorText}
        </div>
      </div>
    );
  }
}

export default InputField;

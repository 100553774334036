import React from 'react';
import DashboardNavItem from './DashboardNavItem';
import DashboardExpandableNavItem from './DashboardExpandableNavItem';
import DashboardNavSeperator from './DashboardNavSeperator';
import PortfolioView from '../dashboard-views/PortfolioView';
import TransactionsView from '../dashboard-views/TransactionsView';
import WorldIndicesView from '../dashboard-views/WorldIndicesView';
import UsSectorsView from '../dashboard-views/UsSectorsView';
import CommoditiesView from '../dashboard-views/CommoditiesView';
import MyAccountView from '../dashboard-views/MyAccountView';

const VIEWS = {
  'portfolio': <PortfolioView/>,
  'transactions': <TransactionsView/>,
  'worldindices': <WorldIndicesView/>,
  'ussectors': <UsSectorsView/>,
  'commodities': <CommoditiesView/>,
  'myaccount': <MyAccountView/>
};

class DashboardNavItemList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selection: 'portfolio'
    };
  }

  render() {
    const changeView = (key) => {
      this.props.changeView(VIEWS[key]);
      this.setState({ selection: key });
    };

    return (
      <ul className="nav flex-column">
        <DashboardNavItem text="Portfolio" iconName="pie"
          selected={this.state.selection === 'portfolio'} onClick={() => changeView('portfolio')} />

        <DashboardNavItem text="Transactions" iconName="table"
          selected={this.state.selection === 'transactions'} onClick={() => changeView('transactions')} />

        <DashboardExpandableNavItem text="Market Data" iconName="globe"
          selection={this.state.selection} changeView={changeView}
          items={[
            {text: "World Indices", view: "worldindices"},
            {text: "US Sectors", view: "ussectors"},
            {text: "Commodities", view: "commodities"}
          ]} />

        <DashboardNavSeperator/>

        <DashboardNavItem text="My Account" iconName="person"
          selected={this.state.selection === 'myaccount'} onClick={() => changeView('myaccount')} />
      </ul>
    );
  }
}

export default DashboardNavItemList;

function TopHoldingsCard(props) {
  const colors = ['bg-secondary', 'bg-tertiary', 'bg-info', 'bg-danger', 'bg-purple'];
  const childItems = props.items.map((item, idx) => {
    const colorIdx = (idx + colors.length) % 4;
    return <HoldingItem key={idx} text={item.text} percent={item.percent} color={colors[colorIdx]}/>;
  });

  return (
    <div className="col-12 col-xl-4 mb-4">
      <div className="card border-light shadow-sm">
        <div className="card-header border-bottom border-light">
          <h2 className="h5 mb-0">Top Holdings</h2>
        </div>
        <div className="card-body">
          {childItems}
        </div>
      </div>
    </div>
  );
}

function HoldingItem(props) {
  const barStyle = {
    width: props.percent + '%'
  };

  return (
    <div className="row align-items-center mb-4">
      <div className="col">
        <div className="progress-wrapper">
          <div className="progress-info">
            <div className="h6 mb-0">{props.text}</div>
            <div className="small font-weight-bold text-dark"><span>{props.percent}%</span></div>
          </div>
          <div className="progress mb-0">
            <div className={"progress-bar " + props.color} role="progressbar" style={barStyle}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopHoldingsCard;

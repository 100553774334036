function Checkbox(props) {
  return (
    <div className="form-check mb-4">
      <input className="form-check-input" type="checkbox" value="" id="terms" />
      <label className="form-check-label" htmlFor="terms">
          {props.text}
      </label>
    </div>
  );
}

export default Checkbox;

function TopGainersLosersCard(props) {
  const cardStyle = {
    minWidth: '250px'
  };

  const columns = props.data.columns.map((item, idx) =>
    <ColumnTitle key={idx} text={item} />
  );
  const entries = props.data.items.map((item, idx) =>
    <Entry key={idx} text={item.text} percent={item.percent} />
  );

  return (
    <div className="col-12 col-xl-2 mb-4" style={cardStyle}>
      <div className="card border-light shadow-sm">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <h2 className="h5 mb-0">{props.title}</h2>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table align-items-center table-flush">
            <thead className="thead-light">
              <tr>{columns}</tr>
            </thead>
            <tbody>{entries}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function ColumnTitle(props) {
  return (<th scope="col">{props.text}</th>);
}

function Entry(props) {
  const UpArrow = "fa-arrow-up text-success";
  const DownArrow = "fa-arrow-down text-danger";
  const ArrowToUse = props.percent > 0 ? UpArrow : DownArrow;

  return (
    <tr>
      <th scope="row">{props.text}</th>
      <td><span className={"fas mr-3 " + ArrowToUse}></span>{props.percent}%</td>
    </tr>
  );
}

export default TopGainersLosersCard;

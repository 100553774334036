function AllHoldingsCard(props) {
  const columns = props.data.columns.map((item, idx) =>
    <ColumnTitle key={idx} text={item} />
  );
  const entries = props.data.items.map((item, idx) =>
    <Entry key={idx} ticker={item.ticker} purchasePrice={item.purchasePrice}
      quantity={item.quantity} currentValue={item.currentValue} change={item.change} />
  );

  return (
    <div className="col-12 col-xl-12 mb-2">
      <div className="card shadow-sm">
        <div className="card-header d-flex flex-row align-items-center flex-0">
          <div className="h4 col text-left">All Holdings</div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0 rounded">
              <thead className="thead-light">
                <tr>
                  {columns}
                </tr>
              </thead>
              <tbody>
                {entries}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function ColumnTitle(props) {
  return (<th className="border-0">{props.text}</th>);
}

function Entry(props) {
  const UpArrow = "fa-arrow-up text-success";
  const DownArrow = "fa-arrow-down text-danger";
  const ArrowToUse = props.change > 0 ? UpArrow : DownArrow;

  return (
    <tr>
      <td>{props.ticker}</td>
      <td>{props.purchasePrice}</td>
      <td>{props.quantity}</td>
      <td>{props.currentValue}</td>
      <td>
        <span className={"fas mr-3 " + ArrowToUse}></span>
        {props.change}
      </td>
    </tr>
  );
}

export default AllHoldingsCard;

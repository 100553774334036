import ChartistGraph from 'react-chartist';
import legend from 'chartist-plugin-legend';

function AssetAllocationCard(props) {
  const options = {
      height: '150px',
      width: '150px',
      showLabel: false,
      donut: true,
      donutWidth: 35,
      donutSolid: true,
      plugins: [
        legend()
      ]
  };

  const cardMargin = {
    paddingLeft: '15px',
    paddingRight: '15px'
  };

  return (
    <div className="col-12 col-xl-4 mb-4">
      <div className="card border-light shadow-sm">
        <div className="card-header d-flex flex-row align-items-center">
          <div className="h4">Asset Allocation</div>
        </div>
        <div className="card-body" style={cardMargin}>
          <ChartistGraph data={props.chartData} type="Pie" options={options} />
        </div>
      </div>
    </div>
  );
}

export default AssetAllocationCard;

import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { Config } from '../../Config';

class ChangePassword {
  resolve = null;
  reject = null;

  action(modelInstance) {
    const userPool = new CognitoUserPool({
      UserPoolId: Config.cognitoUserPoolId,
      ClientId: Config.cognitoClientId
    });

    const user = new CognitoUser({
      Username: modelInstance.emailAddress,
      Pool: userPool
    });

    user.confirmPassword(modelInstance.passwordResetCode, modelInstance.password, {
      onSuccess: this._resetSuccess.bind(this),
      onFailure: this._resetFailure.bind(this)
    });

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  _resetSuccess(data) {
    this.resolve();
  }

  _resetFailure(error) {
    this.reject();
  }
}

export default ChangePassword;

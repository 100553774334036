import BaseModel from './BaseModel';
import Stock from './Stock';
import DataManager from '../DataManager';

class Transaction extends BaseModel {
  date;
  stockTicker;

  get purchasePrice() {
    return parseFloat(this._purchasePrice);
  }
  set purchasePrice(price) {
    this._purchasePrice = price.toString();
  }

  get numberOfShares() {
    return parseInt(this._numberOfShares);
  }
  set numberOfShares(numberOfShares) {
    this._numberOfShares = numberOfShares.toString();
  }

  getStock() {
    return Stock.find(this.stockTicker);
  }

  getIdAsDatabaseCommand() {
    return {
      id: { S: this.id }
    };
  }

  getAsDatabaseCommand() {
    return {
      id: { S: this.id },
      user_sub: { S: DataManager.getInstance().congitoUserIdentityId },
      date: { S: new Date(this.date).toISOString() },
      stockTicker: { S: this.stockTicker },
      purchasePrice: { N: this.purchasePrice.toString() },
      numberOfShares: { N: this.numberOfShares.toString() }
    };
  }
}

export default Transaction;

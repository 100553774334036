import { IconStore } from '../../../IconStore';

function CagrCard(props) {
  const cardStyle = {
    marginBottom: '15px'
  };
  const textStyle = {
    marginBottom: 0
  };
  const subTextStyle = {
    fontSize: 14,
    verticalAlign: 'top'
  };

  return (
    <div className="card border-light shadow-sm" style={cardStyle}>
      <div className="card-body">
        <div className="row d-block d-xl-flex align-items-center">
          <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
            <div className="icon icon-shape icon-md icon-shape-blue rounded mr-4">
              <span className={"fas " + IconStore['percent']}></span>
            </div>
            <div className="d-sm-none">
              <h2 className="h5" style={textStyle}>CAGR</h2>
              <span style={subTextStyle}>Since Inception</span>
              <h3 className="mb-1">{props.value}%</h3>
            </div>
          </div>
          <div className="col-12 col-xl-7 px-xl-0">
            <div className="d-none d-sm-block">
              <h2 className="h5" style={textStyle}>CAGR</h2>
              <span style={subTextStyle}>Since Inception</span>
              <h3 className="mb-1">{props.value}%</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CagrCard;

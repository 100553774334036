export const IconStore = {
  email: 'fa-envelope',
  password: 'fa-lock',
  person: 'fa-user',
  pie: 'fa-chart-pie',
  dollar: 'fa-hand-holding-usd',
  settings: 'fa-cog',
  table: 'fa-table',
  globe: 'fa-globe',
  asterisk: 'fa-star-of-life',
  plus: 'fa-plus',
  percent: 'fa-percentage',
  rightArrow: 'fa-chevron-right',
  downArrow: 'fa-chevron-down',
  user: 'fa-user-circle',
  exitDoor: 'fa-sign-out-alt'
};

import React from 'react';
import DashboardNav from './navigation/DashboardNav';
import DashboardDetailView from './DashboardDetailView';
import PortfolioView from './dashboard-views/PortfolioView';
import User from '../data/models/User';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.changeView = this.changeView.bind(this);

    this.state = {
      activeView: <PortfolioView/>
    };

    const users = User.findAll();
    if (users.length > 0) {
      const user = users[0];
      this.state.user = user;
    }
  }

  componentDidMount() {
    this.state.user.didChange((model) => this.setState({user: model}));
  }

  changeView(view) {
    this.setState({
      activeView: view
    });
  }

  render() {
    return (
      <span>
        <DashboardNav changeView={this.changeView} showAuthView={this.props.showAuthView} name={this.state.user.fullName} />
        <DashboardDetailView activeView={this.state.activeView} showAuthView={this.props.showAuthView} name={this.state.user.fullName} />
      </span>
    );
  }
}

export default Dashboard;

import React from 'react';
import DataManager from './data/DataManager';
import AuthView from './components/auth-views/AuthView';
import Dashboard from './components/Dashboard';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.dataManager = new DataManager();
    this.state = {
      loggedIn: false
    };

    this.validateSession = this.validateSession.bind(this);
    this.showDashboard = this.showDashboard.bind(this);
    this.showAuthView = this.showAuthView.bind(this);
  }

  componentDidMount() {
    // Validate session on app launch
    this.validateSession();
  }

  validateSession() {
    this.dataManager.performOperation('validateSession').then(() => {
      this.showDashboard();
    }).catch(() => {
      // Swallow error (not logged in)
    });
  }

  showDashboard() {
    this.setState({
      loggedIn: true
    });
  }

  showAuthView() {
    this.setState({
      loggedIn: false
    });
  }

  render() {
    if (this.state.loggedIn) {
      return (<Dashboard showAuthView={this.showAuthView} />);
    } else {
      return (<AuthView showDashboard={this.showDashboard} />);
    }
  }
}

export default App;

import React from 'react';
import Chart from 'react-apexcharts';
import MarketChartOptions from './MarketChartOptions';

const fetch = global.fetch;

class WorldIndicesView extends React.Component {
  constructor(props) {
    super(props);

    const sharedOptions = MarketChartOptions;
    sharedOptions.chart.id = 'world-indices';

    this.state = {
      chart: {
        options: sharedOptions,
        series: []
      }
    };
  }

  componentDidMount() {
    fetch('https://api.jashsoft.com/marketdata/world').then((response) => {
      response.json().then((parsedResponse) => {
        const { chart } = this.state;
        this.setState({
          chart: {
            options: {
              ...chart.options,
              xaxis: {
                ...chart.options.xaxis,
                categories: parsedResponse.dates
              }
            },
            series: parsedResponse.prices
          }
        });
      });
    }).catch((error) => {
      // Swallow error
    });
  }

  render() {
    return (
      <div className="col-12 col-xl-8 mb-4">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2"></div>
        <div className="card border-light">
          <div className="card-header card-header-chart d-flex flex-row align-items-center flex-0">
            <div className="h4 col text-left">World Indices</div>
          </div>
          <div className="card-body">
            <Chart options={this.state.chart.options} series={this.state.chart.series} type="line" />
          </div>
        </div>
      </div>
    );
  }
}

export default WorldIndicesView;

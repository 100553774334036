import React from 'react';
import { IconStore } from '../../IconStore';
import DataManager from '../../data/DataManager';

class DashboardNavMobileUserMenu extends React.Component {
  constructor(props) {
    super(props);

    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    const dataManager = DataManager.getInstance();
    dataManager.performOperation('signOutUser').then(() => {
      this.props.showAuthView();
    });
  }

  render() {
    const userMargin = {
      marginBottom: 0
    };

    return (
      <div className="d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-3">
        <h5 className="d-flex d-block" style={userMargin}>{this.props.name}</h5>
        <a href="#" className="btn btn-secondary text-dark btn-sm" onClick={this.signOut}>
          <span className={"fas " + IconStore['exitDoor']}></span>{'\u00A0'}Sign Out
        </a>
      </div>
    );
  }
}

export default DashboardNavMobileUserMenu;

import JashsoftLogo from '../JashsoftLogo';

function DashboardNavMobile(props) {
  const logoStyle = {
    width: '120px',
    height: '70%'
  };

  return (
    <nav className="navbar navbar-dark navbar-theme-primary px-4 col-12 d-md-none">
      <a className="navbar-brand mr-lg-5" href="#">
        <JashsoftLogo fill="white" style={logoStyle} />
      </a>
      <div className="d-flex align-items-center">
        <button className="navbar-toggler d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
}

export default DashboardNavMobile;

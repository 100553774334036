export const CountryMap = {
  af: 'as',
  al: 'eu',
  dz: 'af',
  as: 'oc',
  ad: 'eu',
  ao: 'af',
  ag: 'na',
  az: 'as',
  ar: 'sa',
  au: 'oc',
  at: 'eu',
  bs: 'na',
  bh: 'as',
  bd: 'as',
  am: 'as',
  bb: 'na',
  be: 'eu',
  bm: 'na',
  bt: 'as',
  bo: 'sa',
  ba: 'eu',
  bw: 'af',
  br: 'sa',
  bz: 'na',
  io: 'as',
  sb: 'oc',
  vg: 'na',
  bn: 'as',
  bg: 'eu',
  mm: 'as',
  bi: 'af',
  by: 'eu',
  kh: 'as',
  cm: 'af',
  ca: 'na',
  cv: 'af',
  ky: 'na',
  cf: 'af',
  lk: 'as',
  td: 'af',
  cl: 'sa',
  cn: 'as',
  tw: 'as',
  cx: 'as',
  cc: 'as',
  co: 'sa',
  km: 'af',
  yt: 'af',
  cg: 'af',
  cd: 'af',
  ck: 'oc',
  cr: 'na',
  hr: 'eu',
  cu: 'na',
  cy: 'as',
  cz: 'eu',
  bj: 'af',
  dk: 'eu',
  dm: 'na',
  do: 'na',
  ec: 'sa',
  sv: 'na',
  gq: 'af',
  et: 'af',
  er: 'af',
  ee: 'eu',
  fo: 'eu',
  fk: 'sa',
  fj: 'oc',
  fi: 'eu',
  ax: 'eu',
  fr: 'eu',
  gf: 'sa',
  pf: 'oc',
  dj: 'af',
  ga: 'af',
  ge: 'as',
  gm: 'af',
  ps: 'as',
  de: 'eu',
  gh: 'af',
  gi: 'eu',
  ki: 'oc',
  gr: 'eu',
  gl: 'na',
  gd: 'na',
  gp: 'na',
  gu: 'oc',
  gt: 'na',
  gn: 'af',
  gy: 'sa',
  ht: 'na',
  va: 'eu',
  hn: 'na',
  hk: 'as',
  hu: 'eu',
  is: 'eu',
  in: 'as',
  id: 'as',
  ir: 'as',
  iq: 'as',
  ie: 'eu',
  il: 'as',
  it: 'eu',
  ci: 'af',
  jm: 'na',
  jp: 'as',
  kz: 'as',
  jo: 'as',
  ke: 'af',
  kp: 'as',
  kr: 'as',
  kw: 'as',
  kg: 'as',
  la: 'as',
  lb: 'as',
  ls: 'af',
  lv: 'eu',
  lr: 'af',
  ly: 'af',
  li: 'eu',
  lt: 'eu',
  lu: 'eu',
  mo: 'as',
  mg: 'af',
  mw: 'af',
  my: 'as',
  mv: 'as',
  ml: 'af',
  mt: 'eu',
  mq: 'na',
  mr: 'af',
  mu: 'af',
  mx: 'na',
  mc: 'eu',
  mn: 'as',
  md: 'eu',
  me: 'eu',
  ms: 'na',
  ma: 'af',
  mz: 'af',
  om: 'as',
  na: 'af',
  nr: 'oc',
  np: 'as',
  nl: 'eu',
  an: 'na',
  cw: 'na',
  aw: 'na',
  sx: 'na',
  bq: 'na',
  nc: 'oc',
  vu: 'oc',
  nz: 'oc',
  ni: 'na',
  ne: 'af',
  ng: 'af',
  nu: 'oc',
  nf: 'oc',
  no: 'eu',
  mp: 'oc',
  um: 'na',
  fm: 'oc',
  mh: 'oc',
  pw: 'oc',
  pk: 'as',
  pa: 'na',
  pg: 'oc',
  py: 'sa',
  pe: 'sa',
  ph: 'as',
  pn: 'oc',
  pl: 'eu',
  pt: 'eu',
  gw: 'af',
  tl: 'as',
  pr: 'na',
  qa: 'as',
  re: 'af',
  ro: 'eu',
  ru: 'as',
  rw: 'af',
  bl: 'na',
  sh: 'af',
  kn: 'na',
  ai: 'na',
  lc: 'na',
  mf: 'na',
  pm: 'na',
  vc: 'na',
  sm: 'eu',
  st: 'af',
  sa: 'as',
  sn: 'af',
  rs: 'eu',
  sc: 'af',
  sl: 'af',
  sg: 'as',
  sk: 'eu',
  vn: 'as',
  si: 'eu',
  so: 'af',
  za: 'af',
  zw: 'af',
  es: 'eu',
  ss: 'af',
  eh: 'af',
  sd: 'af',
  sr: 'sa',
  sj: 'eu',
  sz: 'af',
  se: 'eu',
  ch: 'eu',
  sy: 'as',
  tj: 'as',
  th: 'as',
  tg: 'af',
  tk: 'oc',
  to: 'oc',
  tt: 'na',
  ae: 'as',
  tn: 'af',
  tr: 'as',
  tm: 'as',
  tc: 'na',
  tv: 'oc',
  ug: 'af',
  ua: 'eu',
  mk: 'eu',
  eg: 'af',
  gb: 'eu',
  gg: 'eu',
  je: 'eu',
  im: 'eu',
  tz: 'af',
  us: 'na',
  vi: 'na',
  bf: 'af',
  uy: 'sa',
  uz: 'as',
  ve: 'sa',
  wf: 'oc',
  ws: 'oc',
  ye: 'as',
  zm: 'af'
};

import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { Config } from '../../Config';
import DataManager from '../DataManager';
import User from '../models/User';

class ValidateSession {
  action(modelInstance) {
    return new Promise((resolve, reject) => {
      const userPool = new CognitoUserPool({
        UserPoolId: Config.cognitoUserPoolId,
        ClientId: Config.cognitoClientId
      });

      const user = userPool.getCurrentUser();
      if (user) {
        user.getSession((error, data) => {
          if (!error) {
            const dataManager = DataManager.getInstance();
            dataManager.authSession = data;

            const payload = data.idToken.payload;
            new User({
              fullName: payload.name,
              emailAddress: payload.email
            });

            dataManager.getCognitoIdentityId().then(() => {
              resolve();
            });
          } else {
            reject();
          }
        });
      } else {
        reject();
      }
    });
  }
}

export default ValidateSession;

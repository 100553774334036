import DashboardNavDesktopUserMenu from './navigation/DashboardNavDesktopUserMenu';

function DashboardDetailView(props) {
  return (
    <main className="content">
      <DashboardNavDesktopUserMenu showAuthView={props.showAuthView} name={props.name} />
      {props.activeView}
    </main>
  );
}

export default DashboardDetailView;

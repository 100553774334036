import React from 'react';
import { IconStore } from '../../IconStore';
import DataManager from '../../data/DataManager';

class DashboardNavDesktopUserMenu extends React.Component {
  constructor(props) {
    super(props);

    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    const dataManager = DataManager.getInstance();
    dataManager.performOperation('signOutUser').then(() => {
      this.props.showAuthView();
    });
  }

  render() {
    return (
      <span>
        <nav className="navbar navbar-top navbar-expand navbar-dashboard navbar-dark pl-0 pr-2 pb-0">
          <div className="container-fluid px-0">
            <div className="d-flex justify-content-between w-100" id="navbarSupportedContent">
              <div className="d-flex"></div>
              <ul className="navbar-nav align-items-center">
                <li className="nav-item dropdown">
                  <a className="nav-link pt-1 px-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="media d-flex align-items-center">
                      <div className="media-body ml-2 text-dark align-items-center d-none d-md-block">
                        <span className={"font-large fas " + IconStore['user']}></span>{'\u00A0'}{'\u00A0'}
                        <span className="mb-0 font-large font-weight-bold">{this.props.name}</span>{'\u00A0'}
                        <span className={"fas " + IconStore['downArrow']}></span>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dashboard-dropdown dropdown-menu-right mt-2">
                    <a className="dropdown-item font-weight-bold" href="#" onClick={this.signOut}>
                      <span className={"text-danger fas " + IconStore['exitDoor']}></span>
                      Sign Out
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </span>
    );
  }
}

export default DashboardNavDesktopUserMenu;

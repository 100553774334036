const MarketChartOptions = {
  chart: {
    height: '50%',
    animations: {
      enabled: false
    },
    toolbar: {
      show: false
    }
  },
  stroke: {
    width: 2
  },
  colors: ['#b4ed29', '#c23161', '#ae8c1d', '#23d2d4', '#fd7f23', '#9751a1'],
  xaxis: {
    labels: {
      show: false
    },
    axisTicks: {
      show: false
    }
  },
  yaxis: {
    opposite: true,
    labels: {
      formatter: (value, timestamp, opts) => (value/1).toFixed(0) + '%'
    }
  },
  tooltip: {
    x: {
      show: false
    },
    y: {
      formatter: (value, timestamp, opts) => (value/1).toFixed(2) + '%'
    },
    shared: false
  }
};

export default MarketChartOptions;

import DataManager from '../../data/DataManager';
import PortfolioValueCard from './cards/PortfolioValueCard';
import TopSectorsCard from './cards/TopSectorsCard';
import GeographicExposure from './cards/GeographicExposure';
import BetaGaugeCard from './cards/BetaGaugeCard';
import TopGainersLosersCard from './cards/TopGainersLosersCard';
import TopHoldingsCard from './cards/TopHoldingsCard';
import SharpeRatioCard from './cards/SharpeRatioCard';
import CagrCard from './cards/CagrCard';
import AssetAllocationCard from './cards/AssetAllocationCard';
import AllHoldingsCard from './cards/AllHoldingsCard';

function PortfolioView(props) {
  const { portfolio } = DataManager.getInstance();
  return (
    <span>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2"></div>
        <div className="row justify-content-md-center">
          <PortfolioValueCard portfolioValue={portfolio.getPortfolioValue()} chartData={portfolio.getChartData()} />
          <TopSectorsCard chartData={portfolio.getTopSectors()} />
          <GeographicExposure countries={portfolio.getCountryExposure()} />
          <BetaGaugeCard value={portfolio.getBeta()} />
          <TopHoldingsCard items={portfolio.getTopHoldings()}/>
          <TopGainersLosersCard title="Top Gainers" data={portfolio.getTopGainers()} />
          <TopGainersLosersCard title="Top Losers" data={portfolio.getTopLosers()} />
          <div className="col-12 col-sm-3 col-sm-3 mb-4">
            <CagrCard value={portfolio.getCAGR()} />
            <SharpeRatioCard value={portfolio.getSharpeRatio()} />
          </div>
          <AssetAllocationCard chartData={portfolio.getAssetAllocation()} />
          <AllHoldingsCard data={portfolio.getHoldings()} />
        </div>
    </span>
  );
}

export default PortfolioView;

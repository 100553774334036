import ChartistGraph from 'react-chartist';

function PortfolioValueCard(props) {
  const formatter = Intl.NumberFormat();
  const options = {
      height: '200px',
      fullWidth: true,
      showArea: true,
      showLine: false,
      showPoint: false,
      chartPadding: {
        right: 35
      },
      axisX: {
        showLabel: true,
        showGrid: false
      },
      axisY: {
        scaleMinSpace: 40
      },
      lineSmooth: false
  };

  return (
    <div className="col-12 col-xl-8 mb-4">
      <div className="card shadow-sm">
        <div className="card-header d-flex flex-row align-items-center flex-0">
          <div className="h4 col text-left">Portfolio Value</div>
          <div className="h2 col text-right">${formatter.format(props.portfolioValue)}</div>
        </div>
        <div className="card-body p-3">
          <ChartistGraph data={props.chartData} type="Line" options={options} />
        </div>
      </div>
    </div>
  );
}

export default PortfolioValueCard;

import BaseModel from './BaseModel';

class Stock extends BaseModel {
  ticker;
  sector;
  country;
  lastPrice;
}

export default Stock;

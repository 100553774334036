import JashsoftLogo from '../JashsoftLogo';
import React from 'react';
import DataManager from '../../data/DataManager';
import User from '../../data/models/User';
import SignInFields from './SignInFields';
import SignUpFields from './SignUpFields';
import ForgotPasswordFields from './ForgotPasswordFields';
import ConfirmEmailView from './ConfirmEmailView';
import { ErrorCode } from '../../ErrorCode';

const getUser = () => {
  const users = User.findAll();
  if (users.length > 0) {
    return users[0];
  } else {
    return new User();
  }
};

class AuthView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newUser: false,
      forgotPassword: false,
      pendingEmailConfirm: false
    };

    this.signupFieldsComponent = React.createRef();
    this.signinFieldsComponent = React.createRef();
    this.forgotPasswordComponent = React.createRef();

    this.registerUser = this.registerUser.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  registerUser(fullName, emailAddress, password) {
    const context = this;
    const dataManager = DataManager.getInstance();
    const user = getUser();
    user.fullName = fullName;
    user.emailAddress = emailAddress;
    user.password = password;

    dataManager.performOperation('registerUser', user).then(() => {
      this.setState({
        pendingEmailConfirm: true
      });
    }).catch(() => {
      context.signupFieldsComponent.current.showError();
    });
  }

  loginUser(emailAddress, password) {
    const context = this;
    const dataManager = DataManager.getInstance();
    const user = getUser();
    user.emailAddress = emailAddress;
    user.password = password;

    dataManager.performOperation('loginUser', user).then(() => {
      this.props.showDashboard();
    }).catch((code) => {
      if (code === ErrorCode.Login.NotConfirmed) {
        this.setState({
          pendingEmailConfirm: true
        });
      } else {
        context.signinFieldsComponent.current.showError();
      }
    });
  }

  forgotPassword(emailAddress) {
    const context = this;
    const dataManager = DataManager.getInstance();
    const user = getUser();
    user.emailAddress = emailAddress;

    dataManager.performOperation('forgotPassword', user).then(() => {
      this.forgotPasswordComponent.current.showSuccessAlert();
    }).catch(() => {
      context.forgotPasswordComponent.current.showSuccessAlert();
    });
  }

  changePassword(emailAddress, code, newPassword) {
    const context = this;
    const dataManager = DataManager.getInstance();
    const user = getUser();
    user.emailAddress = emailAddress;
    user.passwordResetCode = code;
    user.password = newPassword;

    dataManager.performOperation('changePassword', user).then(() => {
      this.setState({ forgotPassword: false });
    }).catch(() => {
      context.forgotPasswordComponent.current.showFailureAlert();
    });
  }

  render() {
    let text = 'Sign into your account';
    let form = <SignInFields showSignUp={() => this.setState({ newUser: true })}
      showForgotPassword={() => this.setState({ forgotPassword: true })}
      loginUser={this.loginUser} ref={this.signinFieldsComponent} />;

    if (this.state.newUser) {
      text = 'Create an account';
      form = <SignUpFields showSignIn={() => this.setState({ newUser: false })}
        registerUser={this.registerUser} ref={this.signupFieldsComponent} />;
    }
    if (this.state.forgotPassword) {
      text = 'Forgot your password?';
      form = <ForgotPasswordFields cancelForgotPassword={() => this.setState({ forgotPassword: false })}
        forgotPassword={this.forgotPassword} changePassword={this.changePassword} ref={this.forgotPasswordComponent} />;
    }
    if (this.state.pendingEmailConfirm) {
      text = 'Confirm your E-mail Address';
      form = <ConfirmEmailView />;
    }

    const logoStyle = {
      marginTop: '-10px',
      marginBottom: '25px',
      height: '50px'
    };

    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <div className="container">
            <p className="text-center" style={logoStyle}>
              <JashsoftLogo fill="black" style={logoStyle} />
            </p>
            <div className="row justify-content-center form-bg-image">
              <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h1 className="mb-0 h3">
                      {text}
                    </h1>
                  </div>
                    {form}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default AuthView;

function ActionButton(props) {
  let classes = "btn btn-primary";
  if (props.fullWidth) {
    classes += " btn-block";
  }

  return (
    <button onClick={props.onClick} disabled={props.disabled} className={classes}>{props.text}</button>
  );
}

export default ActionButton;

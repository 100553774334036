import React from 'react';

function HoldingsTable(props) {
  const columns = props.data.columns.map((item, idx) =>
    <ColumnTitle key={idx} text={item} />
  );
  const entries = props.data.items.map((item) =>
    <Entry key={item.id} id={item.id} date={item.date} ticker={item.stockTicker} purchasePrice={item.purchasePrice}
      quantity={item.numberOfShares} currentValue={item.currentValue} change={item.change} />
  );

  return (
    <div className="card border-light shadow-sm mb-4">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-centered table-nowrap mb-0 rounded">
            <thead className="thead-light">
              <tr>
                {columns}
                <ColumnTitle text="Actions" />
              </tr>
            </thead>
            <tbody>
              {entries}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function ColumnTitle(props) {
  return (<th className="border-0">{props.text}</th>);
}

class Entry extends React.Component {
  render() {
    return (
      <tr>
        <td>{this.props.date}</td>
        <td>{this.props.ticker}</td>
        <td>{this.props.purchasePrice}</td>
        <td>{this.props.quantity}</td>
        <td>
          <button data-id={this.props.id} className="btn holdings-table-action-btn btn-edit" type="button" data-toggle="modal" data-target="#modal-edit-transaction">
            Edit
          </button>
          {'\u00A0'}
          <button data-id={this.props.id} className="btn holdings-table-action-btn btn-delete" type="button" data-toggle="modal" data-target="#modal-delete-transaction">
            Delete
          </button>
        </td>
      </tr>
    );
  }
}

export default HoldingsTable;

import { v4 as uuid } from 'uuid';
import DataManager from '../DataManager';
import OperationType from '../OperationType';

class BaseModel {
  static _instances = {};

  static findAll() {
    const instances = BaseModel._instances[this.name] || [];
    return instances;
  }

  static find(primaryKey) {
    const instances = BaseModel._instances[this.name] || [];
    return instances.filter((instance) => instance.id === primaryKey)[0];
  }

  static fetch(key) {
    let modelClassOrInstance = {
      constructor: this
    };

    if (key) {
      modelClassOrInstance = new this({
        id: key
      });
    }

    const dataManager = DataManager.getInstance();
    return dataManager.performModelOperation(OperationType.READ, modelClassOrInstance);
  }

  id = null;

  listeners = [];

  constructor(initialData) {
    if (!BaseModel._instances[this.constructor.name]) {
      BaseModel._instances[this.constructor.name] = [];
    }

    if (initialData && initialData.id) {
      const existingModel = this.constructor.find(initialData.id);
      if (existingModel) throw new Error('Model with id already exists.');
    } else {
      this.id = uuid();
    }

    BaseModel._instances[this.constructor.name].push(this);

    if (initialData) {
      const keys = Object.keys(initialData);
      keys.forEach((prop) => {
        this[prop] = initialData[prop];
      });
    }
  }

  toJSON(preserveId) {
    const props = Object.keys(this);
    const methods = Object.getOwnPropertyNames(Object.getPrototypeOf(this));

    if (!preserveId) {
      // Remove keys added by base class
      var index = props.indexOf('id');
      if (index !== -1) {
        props.splice(index, 1);
      }
    }

    const json = {};
    props.forEach((prop) => {
      // Don't copy private props
      if (prop.charAt(0) !== '_') {
        json[prop] = this[prop];
      } else if (methods.indexOf(prop.substr(1)) !== -1) {
        // Copy when getter exists for private prop
        const propWithGetter = prop.substr(1);
        json[propWithGetter] = this[propWithGetter];
      }
    });

    return json;
  }

  save() {
    const dataManager = DataManager.getInstance();
    return dataManager.performModelOperation(OperationType.UPDATE, this).then(() => {
      this.listeners.forEach((callback) => callback(this));
    });
  }

  delete() {
    const dataManager = DataManager.getInstance();
    return dataManager.performModelOperation(OperationType.DELETE, this).then(() => {
      const instances = BaseModel._instances[this.constructor.name];
      const idx = instances.indexOf(this);
      BaseModel._instances[this.constructor.name].splice(idx, 1);
    });
  }

  didChange(callback) {
    this.listeners.push(callback);
  }
}

export default BaseModel;

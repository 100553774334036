import BaseModelHandler from './BaseModelHandler';
import Stock from '../models/Stock';

class StockHandler extends BaseModelHandler {
  modelType = Stock;

  read(modelInstance) {
    return fetch('https://api.jashsoft.com/portfoliodata/company', {
      method: 'POST',
      headers: {
        'Content-Type':'application/octet-stream'
      },
      body: JSON.stringify([modelInstance.id])
    }).then((response) => {
      return response.json().then((parsedResponse) => {
        const ticker = modelInstance.id;
        const data = parsedResponse[ticker];

        modelInstance.ticker = ticker;
        modelInstance.sector = data.sector;
        modelInstance.country = data.country;
        modelInstance.lastPrice = data.lastPrice;

        return modelInstance;
      });
    }).catch((error) => {
      // Swallow error…
    });
  }
}

export default StockHandler;

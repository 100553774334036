import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { CognitoIdentityClient, GetIdCommand } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { Config } from '../Config';
import Portfolio from './models/Portfolio';
import RegisterUser from './operation-handlers/RegisterUser';
import LoginUser from './operation-handlers/LoginUser';
import SignOutUser from './operation-handlers/SignOutUser';
import ValidateSession from './operation-handlers/ValidateSession';
import ForgotPassword from './operation-handlers/ForgotPassword';
import ChangePassword from './operation-handlers/ChangePassword';
import UserHandler from './operation-handlers/UserHandler';
import TransactionHandler from './operation-handlers/TransactionHandler';
import StockHandler from './operation-handlers/StockHandler';

class DataManager {
  static _instance;

  modelHandlers = [
    UserHandler,
    TransactionHandler,
    StockHandler
  ];

  operationHandlers = {
    'registerUser': new RegisterUser(),
    'loginUser': new LoginUser(),
    'signOutUser': new SignOutUser(),
    'validateSession': new ValidateSession(),
    'forgotPassword': new ForgotPassword(),
    'changePassword': new ChangePassword()
  };

  _models = new Map();

  _modelHandlers = new Map();

  authSession = null;

  congitoUserIdentityId = null;

  portfolio = null;

  static getInstance() {
    if (!DataManager._instance) {
      throw new Error('DataManager not initialized');
    }
    return DataManager._instance;
  }

  constructor() {
    DataManager._instance = this;

    this.modelHandlers.forEach((modelHandler) => {
      this.registerModelHandler(modelHandler);
    });

    this.portfolio = new Portfolio();
  }

  registerModelHandler(modelHandler) {
    const handler = new modelHandler();
    const { modelType } = handler;
    if (!modelType) {
      throw new Error('Model Handler is not associated with a model.');
    }

    this._models.set(modelType.name, modelType);
    this._modelHandlers.set(modelType, handler);
  }

  performOperation(operationName, modelInstance) {
    const handler = this.operationHandlers[operationName];
    if (!handler) {
      throw new Error('Operation Handler not found for operation');
    }

    return handler.action(modelInstance);
  }

  performModelOperation(operationType, modelInstance) {
    const classType = modelInstance.constructor;
    if (!this._modelHandlers.has(classType)) {
      throw new Error(`Model Handler not registered for ${classType.name}`);
    }

    const modelHandler = this._modelHandlers.get(classType);
    return modelHandler.handlers[operationType](modelInstance);
  }

  getModelClassFor(modelName) {
    return this._models.get(modelName);
  }

  getAwsCognitoLogin() {
    const userPool = new CognitoUserPool({
      UserPoolId: Config.cognitoUserPoolId,
      ClientId: Config.cognitoClientId
    });

    const user = userPool.getCurrentUser();
    if (!user) {
      throw new Error('Attempt to access resources without auth');
    }

    const loginMap = {};
    const cognitoKey = user.client.endpoint.split('https://')[1] + user.pool.userPoolId;
    loginMap[cognitoKey] = this.authSession.getIdToken().getJwtToken();
    return loginMap;
  }

  getCognitoIdentityId() {
    const client = new CognitoIdentityClient({
      region: Config.awsRegion
    });

    const cognitoMap = {
      Logins: this.getAwsCognitoLogin(),
      IdentityPoolId: Config.cognitoIdentityPoolId
    };

    const command = new GetIdCommand(cognitoMap);
    return client.send(command).then((response) => {
      this.congitoUserIdentityId = response.IdentityId;
    });
  }

  getDatabaseClient() {
    return new DynamoDBClient({
      region: Config.awsRegion,
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: Config.awsRegion },
        client: new CognitoIdentityClient(),
        identityPoolId: Config.cognitoIdentityPoolId,
        logins: this.getAwsCognitoLogin()
      })
    });
  }
}

export default DataManager;

import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { Config } from '../../Config';
import DataManager from '../DataManager';
import User from '../models/User';

class LoginUser {
  resolve = null;
  reject = null;

  action(modelInstance) {
    const userPool = new CognitoUserPool({
      UserPoolId: Config.cognitoUserPoolId,
      ClientId: Config.cognitoClientId
    });

    const user = new CognitoUser({
      Username: modelInstance.emailAddress,
      Pool: userPool
    });

    const authDetails = new AuthenticationDetails({
      Username: modelInstance.emailAddress,
      Password: modelInstance.password
    });

    user.authenticateUser(authDetails, {
      onSuccess: this._loginSuccess.bind(this),
      onFailure: this._loginFailure.bind(this)
    });

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  _loginSuccess(data) {
    const dataManager = DataManager.getInstance();
    dataManager.authSession = data;

    const users = User.findAll();
    if (users.length > 0) {
      const user = users[0];
      user.fullName = data.idToken.payload.name;
    }

    dataManager.getCognitoIdentityId().then(() => {
      this.resolve();
    });
  }

  _loginFailure(error) {
    const { code } = error;
    this.reject(code);
  }
}

export default LoginUser;

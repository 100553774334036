import BaseModel from './BaseModel';

class User extends BaseModel {
  fullName;
  emailAddress;
  password;
  passwordResetCode;
}

export default User;
